<template>
    <section id="artikelliste">
        <appSideNavigation></appSideNavigation>
        <div class="sidenav">
            <router-link to="/">Home</router-link>
            <router-link to="/dashboard">Dashboard</router-link>
        </div>
        <router-view/>
        <h1>Artikelliste</h1>
        <div v-for="obj in list" :key="obj.id">
            <router-link :to="{ name: 'Artikel', params: { id: obj.id , name: obj.name}}">{{ obj.name }}</router-link>
        </div>
    </section>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import Util from '../mixins/util'

export default {
    data () {
    return {
            list: [
                {name:"Artikel",id:123},
                {name:"Artikel2",id:124},
                {name:"Artikel3",id:125},
                {name:"Artikel4",id:126},
            ]
        }
    },
    mounted: function () {
        this.navigateDependingOnSession()
    },
    methods: {
        log: function () {
            console.log(this.$router.params);
        }
    },
    components: {
        appSideNavigation: SideNavigation
    },
    mixins: [Util]
};
</script>
